<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addOrUpdate" :class="isLoading ? 'is-busy' : ''">
            <div class="mb-3 form-floating">
              <input
                v-model.trim="form.name"
                ref="name"
                type="text"
                class="form-control"
                id="fname"
                placeholder="Insert exercise name..."
              >
              <label for="fname">Name</label>
              <div class="form-text">Minimum 3 characters.</div>
            </div>

            <div class="my-3 form-floating">
              <textarea
                v-model.trim="form.description"
                ref="description"
                rows="3"
                class="form-control"
                id="fdescription"
                placeholder="Insert instructions..."
              ></textarea>
              <label for="fdescription">Instructions</label>
            </div>

            <div class="my-3 form-floating">
              <input
                v-model.trim="form.videoUrl"
                ref="resource"
                type="text"
                class="form-control"
                id="fvideo"
                placeholder="Insert video url..."
              >
              <label for="fvideo">Video URL</label>
              <VideoUrlSample class="mt-3"></VideoUrlSample>
            </div>

            <ErrorMessage v-if="error" :error="error"></ErrorMessage>

            <SubmitButton
              :text="submitText"
              :textBusy="'Please wait...'"
              :isLoading="isLoading"
              :disabled="isLoading || isSubmitDisabled"
              class="btn btn-primary"
            ></SubmitButton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpPost } from '@/core/http';

const formDefaultData = () => ({
  exerciseLibraryId: null,
  name: '',
  description: '',
  videoUrl: null,
  videoSource: null,
});

export default {
  name: 'AdminLibraryModal',
  components: {
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
    VideoUrlSample: () => import('@/elements/VideoUrlSample'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    exercise: {
      type: Object,
      default: null,
    },
  },
  computed: {
    action() {
      return this.exercise ? 'edit' : 'new';
    },
    title() {
      return this.action === 'edit' ? 'Edit exercise library' : 'New exercise library';
    },
    submitText() {
      return this.action === 'edit' ? 'Save' : 'Create';
    },
    isSubmitDisabled() {
      return this.form.name.length < 3;
    },
  },
  methods: {
    async addOrUpdate() {
      this.isLoading = true;
      this.error = null;
      try {
        const payload = { ...this.form };
        if (payload.videoUrl) {
          payload.videoSource = 'youtube';
        } else {
          payload.videoUrl = null;
          payload.videoSource = null;
        }
        const res = await httpPost('/admin/library/', payload);
        this.$emit('addOrUpdate', res.data);
        this.form = formDefaultData();
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit('close');
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      form: formDefaultData(),
    };
  },
  created() {
    if (this.exercise) {
      const {
        exerciseLibraryId,
        name,
        description,
        videoUrl,
        videoSource,
      } = this.exercise;

      this.form = {
        exerciseLibraryId,
        name,
        description,
        videoUrl,
        videoSource,
      };
    }
  },
};
</script>
